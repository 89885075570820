import { Container } from '../Components/Container/Container'
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from '../Components/VerticalSpacer/VerticalSpacer';
import { Title } from '../Components/Title/Title';

export function WebshopDisabled() {
  const { t } = useTranslation();
  return (
    <Container>
      <VerticalSpacer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <div style={{ width: '50%', marginTop: 25 }}>
            <Title level={1} title={t('webshop_disabled')} />
          </div>
        </div>
      </VerticalSpacer>
    </Container>
  );
}
